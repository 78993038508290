<template>
  <div v-loading="loading" class="form-app-outer">
    <div class="top-info">
      <span class="big">欢迎来到，券铭！</span>
      <span class="small">协同工作台</span>
    </div>
    <div class="search-outer">
      <el-input
        size="small"
        clearable
        placeholder="搜索"
        prefix-icon="el-icon-search"
        v-model="searchForm"
      ></el-input>
    </div>
    <div class="worm-list">
      <div class="work-item bg1" @click="to('unfinished')">
        <span>待办事宜</span>
        <span class="num">{{ taskCount.todo }}</span>
      </div>
      <div class="work-item bg2" @click="to('finished')">
        <span>已办事宜</span>
        <span class="num">{{ taskCount.ido }}</span>
      </div>
    </div>
    <div class="worm-list">
      <div class="work-item bg3" @click="to('submit')">
        <span>我的请求</span>
        <span class="num">{{ taskCount.mySubmited }}</span>
      </div>
      <div class="work-item bg4" @click="to('cc')">
        <span>抄送我的</span>
        <span class="num">{{ taskCount.cc }}</span>
      </div>
    </div>
    <!-- <el-row :gutter="20">
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
        <div class="count" @click="to('unfinished')">
          <div>
            <p>待我处理</p>
            <div>{{ taskCount.todo }}</div>
          </div>
          <img src="../../../assets/image/pending.png" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
        <div class="count" @click="to('submit')">
          <div>
            <p>我发起的</p>
            <div>{{ taskCount.mySubmited }}</div>
          </div>
          <img src="../../../assets/image/submit.png" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
        <div class="count" @click="to('cc')">
          <div>
            <p>抄送我的</p>
            <div>{{ taskCount.cc }}</div>
          </div>
          <img src="../../../assets/image/cc.png" />
        </div>
      </el-col>
    </el-row> -->
    <div class="group" v-if="searchForm && searchForm.trim() !== ''">
      <div class="group-title">搜索结果</div>
      <div class="group-container">
        <div
          class="group-item"
          v-for="(item, index) in searchResult"
          :key="index"
          @click="enterItem(item)"
        >
          <div class="item-center">
            <i
              :class="item.logo.icon"
              :style="'background: ' + item.logo.background"
            ></i>
            <ellipsis class="item-name" hover-tip :content="item.formName" />
          </div>
        </div>
        <el-empty
          v-if="searchResult.length === 0"
          :image-size="100"
          :description="`未搜索到 '${searchForm}' 相关表单`"
        ></el-empty>
      </div>
    </div>
    <div v-else>
      <div class="group" v-if="recentlyUsed && recentlyUsed.length > 0">
        <div class="group-title">
          最近使用
          <el-link
            style="float: right; margin-top: 0.7rem; font-size: 1.3rem"
            :underline="false"
            type="text"
            icon="el-icon-delete"
            @click="clearUsed"
            >清空</el-link
          >
        </div>
        <div class="group-container">
          <div
            class="group-item"
            v-for="(item, index) in recentlyUsed"
            :key="index"
            @click="enterItem(item)"
          >
            <div>
              <i
                :class="item.logo.icon"
                :style="'background: ' + item.logo.background"
              ></i>
              <ellipsis class="item-name" hover-tip :content="item.formName" />
            </div>
          </div>
        </div>
      </div>

      <template v-if="formList.list.length > 0">
        <div
          v-for="group in formList.list"
          :key="group.id"
          :class="{
            group:
              group.items.length > 0 && group.id > 0 && group.id != 2
                ? true
                : false,
          }"
          v-show="group.items.length > 0 && group.id > 0 && group.id != 2"
        >
          <div class="group-title">{{ group.name }}</div>
          <div class="group-container">
            <div
              class="group-item"
              v-for="(item, index) in group.items"
              :key="item.id"
              @click="enterItem(item)"
            >
              {{ item.id }}
              <div>
                <i
                  :class="item.logo.icon"
                  :style="'background: ' + item.logo.background"
                ></i>

                <ellipsis
                  class="item-name"
                  hover-tip
                  :content="item.formName"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="nodata"><span>暂无可用申请单</span></div>
      </template>
    </div>

    <w-dialog
      v-if="!isMobile"
      :ok-loading="submitLoading"
      :title="`发起 - ${selectForm.formName}`"
      closeFree
      width="1000px"
      v-model="openItemDl"
      okText="提 交"
      @cancel="openItemDl = false"
      @ok="submitForm"
    >
      <initiate-process
        ref="processForm"
        @render-ok="submitLoading = false"
        :code="selectForm.formId"
        v-if="openItemDl"
        @fail="submitLoading = false"
        @ok="(openItemDl = false), (submitLoading = false)"
      />
    </w-dialog>
  </div>
</template>

<script >
// <script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.4.0.js"><\/script>

import { getGroupModelsByUser, getProcessCountData } from "@/api/modelGroup";
import InitiateProcess from "../InitiateProcess";
import uniWebview from "@dcloudio/uni-webview-js";
export default {
  name: "ApprovalGroup",
  components: { InitiateProcess },
  data() {
    return {
      taskCount: {
        todo: 0,
        mySubmited: 0,
        cc: 0,
      },
      submitLoading: false,
      recentlyUsed: [],
      //searchResult: [],
      searchForm: "",
      loading: false,
      openItemDl: false,
      selectForm: {},
      formItem: {},
      actives: [],
      formList: {
        list: [],
        inputs: "",
        searchResult: [],
      },
      pending: {
        list: [],
      },
      popupStyle: {
        height: "100%",
        width: "100%",
        background: "#f7f7f9",
      },
    };
  },
  mounted() {
    console.log("formapp");
    this.getGroupModels();
    this.getCountData();
    this.recentlyUsed = JSON.parse(
      localStorage.getItem(`recentlyUsed:${(this.loginUser || {}).id}`) || "[]"
    );
  },
  computed: {
    isMobile() {
      return true;
      //return window.screen.width < 450;
    },
    loginUser() {
      return this.$store.state.loginUser;
    },
    searchResult() {
      let result = [];
      this.formList.list.forEach((group) => {
        group.items.forEach((item) => {
          if (item.formName.indexOf(this.searchForm) > -1) {
            result.push(item);
          }
        });
      });
      return result;
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     console.log("工作台````beforeRouteEnter``````" + from.path);
  //     if (from.path == "/mbinitiate") {
  //       from.$vnode.parent.componentInstance.cache = {};
  //       from.$vnode.parent.componentInstance.keys = [];
  //     }
  //   });
  // },
  methods: {
    send() {
      // alert("开始传数据sss")
      // uniWebview.postMessage({
      //    data: { name:"aaa"}
      //    })
      //  uniWebview.navigateBack({
      //  delta: 1
      //   });
      //  wx.miniProgram.postMessage("微信是")
      //    uniWebview.redirectTo({
      //       url: 'pages/scan-sell/scan-sell'
      //     })
    },
    getGroupModels() {
      this.loading = true;
      const idSet = new Set();
      getGroupModelsByUser()
        .then((rsp) => {
          this.loading = false;
          this.formList.list = rsp.data;

          this.formList.list.forEach((group) => {
            this.actives.push(group.name);
            group.items.forEach((item) => {
              item.logo = JSON.parse(item.logo);
              idSet.add(item.formId);
            });
          });
          this.groups = rsp.data;
          //   localStorage.setItem("列表" + this.formList);
          this.filterRecentlyUsed(idSet);
        })
        .catch((err) => {
          this.loading = false;
          this.$err(err, "获取分组异常~");
          this.recentlyUsed.length = 0;
        });
    },
    async filterRecentlyUsed(collect) {
      this.recentlyUsed = this.recentlyUsed.filter((v) =>
        collect.has(v.formId)
      );
    },
    getCountData() {
      getProcessCountData().then((rsp) => {
        this.taskCount = rsp.data;
      });
    },
    to(path) {
      this.$router.push("/workspace/" + path);
    },
    enterItem(item) {
      console.log("点击了" + item.formName);
      // alert(item.formName + "`````" + item.id);
      // return;
      if (!this.$isNotEmpty(item.processDefId)) {
        this.$message.warning("该流程还未发布😥~");
        return;
      }
      this.selectForm = item;
      this.openItemDl = true;
      this.submitLoading = true;
      this.recentlyUsed.removeByKey("formId", item.formId);
      this.recentlyUsed.unshift(item);
      if (this.recentlyUsed.length >= 20) {
        this.recentlyUsed.splice(1, this.recentlyUsed.length - 1);
      }
      localStorage.setItem(
        `recentlyUsed:${(this.loginUser || {}).id}`,
        JSON.stringify(this.recentlyUsed)
      );

      if (this.isMobile) {
        console.log("`````````````````" + item.formName);
        console.log("`````````````````" + item.modelNo);
        if (item.modelNo != "" && item.modelNo != null) {
          //原系统的一些申请
          //4.0新加的如下固定模板申请
          //1.调拨单申请2.销售礼券申请 ，3免费礼券申请,4销售折扣申请,5礼券延期申请,空串表示非我们定义的模板
          //this.$router.push("/mbinitiate?code=" + item.formId);
          //清空之前的购物车，初始化自己的购物车结构
          this.$store.commit("clearTickCartList");
          let carList = [
            {
              userName: "",
              userPhone: "",
              carListbyUser: [],
            },
          ];

          console.log("外部表单的页面");
          // this.$message.warning("暂未开通，敬请期待!");
          this.$store.commit("setTickCarList", carList);
          this.$router.push({
            path: "/mbinitiate",
            query: { code: item.formId, modeNo: item.modelNo },
          });
        } else {
          console.log("以前的页面");
          this.$router.push("/mbinitiateDefault?code=" + item.formId);
        }
      }
    },
    clearUsed() {
      this.recentlyUsed = [];
      localStorage.setItem(`recentlyUsed:${(this.loginUser || {}).id}`, "[]");
    },
    submitForm() {
      this.$refs.processForm.validate((validForm, validProcess) => {
        if (!this.isMobile) {
          if (validForm && validProcess) {
            this.submitLoading = true;
            this.$refs.processForm.submit();
          } else {
            this.$message.warning("请完善表单/流程选项😥");
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form-app-outer {
  background-color: #f8f8f8;
  background-image: url("../../../assets/image/bg6.png");
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 100vh;
  .nodata {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
    font-size: 1.3rem !important;
    color: #666;
  }
  .top-info {
    display: flex;
    flex-direction: column;
    color: #333;
    font-weight: 600;
    padding: 4rem 2rem 1.2rem;
    .big {
      font-size: 1.8rem;
    }
    .small {
      margin-top: 0.5rem;
      font-size: 1.5rem;
    }
  }
  .worm-list {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
    .work-item {
      width: 42%;
      height: 8rem;
      display: flex;
      padding: 1.5rem;
      flex-direction: column;
      justify-content: center;

      background-repeat: no-repeat;
      background-size: 100%;
      font-size: 1.4rem;
      .num {
        margin-top: 0.6rem;
      }
    }
    .bg1 {
      background-image: url("../../../assets/image/dbsy.png");
    }
    .bg2 {
      background-image: url("../../../assets/image/y8.png");
    }
    .bg3 {
      background-image: url("../../../assets/image/k7.png");
    }
    .bg4 {
      background-image: url("../../../assets/image/bh.png");
    }
  }
  .search-outer {
    margin-bottom: 1rem;
    padding-left: 4%;
    box-sizing: border-box;
    /deep/ .el-input {
      width: 15rem;
      height: 3rem;
      line-height: 3rem;

      font-size: 1.3rem;
    }
    /deep/ .el-input__inner {
      background-color: rgba(0, 0, 0, 0);
      border-radius: 1rem;
      height: 3rem;
      line-height: 3rem;
      font-size: 1.3rem;
    }
    /deep/ .el-input__icon {
      height: 3rem;
      width: 3rem;
      line-height: 3rem;
      font-size: 1.3rem;
    }
    /deep/ .el-input__prefix {
      left: 0.5rem;
    }
  }
  .group {
    border-radius: 1rem;
    padding: 2rem;
    margin: 0 3% 1rem;
    background-image: url("../../../assets/image/item.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .group-title {
      font-size: 1.4rem;
      color: #303133;
      margin-bottom: 5px;
    }

    .group-container {
      width: 100%;
      display: grid;
      grid-template-columns: 23% 23% 23% 23%;
      justify-content: space-around;
      padding: 1rem 0;
    }

    .group-item {
      display: flex;
      cursor: pointer;
      border-radius: 0.8rem;
      padding: 0.6rem 0;
      text-align: center;
      margin-bottom: 1rem;
      justify-content: center;
      align-items: center;

      i {
        padding: 0.6rem;
        border-radius: 8px;
        font-size: 20px;
        color: #fff;
        background: linear-gradient(
          220.59deg,
          #006c50 0%,
          #64af00 100%
        ) !important;
        height: 4rem;
        width: 4rem;
        display: flex;
        font-size: 2rem;
        justify-content: center;
        align-items: center;
        line-height: 3.5rem;
        &:hover {
          box-shadow: 0 0 15px 0 #9f9999;
          //padding: 10px;
        }
      }

      .item-name {
        font-size: 1.3rem;
        color: #303133;
        max-width: 20vw;

        margin-top: 0.3rem;
      }

      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .group:nth-of-type(odd) {
    i {
      background: linear-gradient(135deg, #ffd11a 0%, #d59e00 100%) !important;
    }
  }
  .count {
    cursor: pointer;
    position: relative;
    background: @theme-aside-bgc;
    border-radius: 8px;
    height: 80px;
    margin: 0 15px 20px 15px;
    & > div {
      left: 15px;
      position: absolute;
      div {
        color: #b9b8b8;
        font-size: 17px;
        font-weight: bold;
      }
    }
    p {
      color: #303133;
      font-size: 14px;
    }
    img {
      position: absolute;
      right: 15px;
      top: 20px;
      width: 40px;
      height: 40px;
    }
    &:hover {
      box-shadow: 0 0 10px #eeeeee;
    }
  }
}
</style>
